import { env } from './helpers/env';

export const TestTypeProgram = {
  PP: 'PP',
  IP: 'IP',
};

export const TestTypeFormat = {
  CBT: 'CBT',
  PBT: 'PBT',
};

export const PURCHASE = {
  STATUS: {
    PENDING: 10,
    AWAITING_BILLING: 40,
    CANCELED: 50,
    CONFIRMED: 60,
    CONFIRMED_NO_INVOICE: 70,
  },
};

export const ARTICLE_FAMILIES = {
  TEST_TYPE: 'testtype',
  PS: 'ps',
  TRANSACTION_FEES: 'transaction_fees',
  SHIPPING_FEES: 'shipping_fees',
  BOOK: 'book',
  ONLINE: 'online',
};

export const PRODUCT_AND_SERVICE = {
  LOGISTIC_CODE_SUPER_EXPRESS: 'SUPER_EXPRESS',
};

export const TEST_TYPE_FAMILY_COLOR = {
  TOEIC_LR_LIGHT_BLUE: '#6CA6DB',
  TOEIC_SW_LILA: '#AB83B9',
  TOEIC_BRIDGE_ORANGE: '#D47500',
  TOEFL_PRIMARY_DARK_BLUE: '#280091',
  TOEFL_ITP_GREY: '#8D968E',
  TOEFL_IBT_TEAL_BLUE: '#077F83',
  TOEFL_JUNIOR_PURPLE: '#863275',
  WORKFORCE_BRIGHT_BLUE: '#0077C8',
  WIDAF_DARK_BLUE: '#003082',
  TFI_BRIGHT_BLUE: '#0077C8',
  ED_LIGHT_GREEN: '#00A675',
  CRITERION_DARK_BLUE: '#003082',
  GRE_PURPLE: '#630f54',
  WORKFORCE_JOB_FIT: '#5caba5',
  WORKFROCE_CAREER_DEV: '#83a42a',
  ETS_GLOBAL_BLUE: '#003082',
};

export const PROGRAMME_COLOR = {
  TOEIC_DARK_GREEN: '#005734',
  TOEFL_TEAL_BLUE: '#077F83',
  WORK_FORCE_BRIGHT_BLUE: '#3072be',
  ED_LIGHT_GREEN: '#00A675',
  CRITERION_DARK_BLUE: '#003082',
  TFI_BRIGHT_BLUE: '#3072be',
  WIDAF_DARK_BLUE: '#003082',
  GRE_PURPLE: '#630f54',
  ETS_GLOBAL_BLUE: '#003082',
};

export const DOCUMENT_TYPE = {
  NEWS: 'NEWS',
  CASE_STUDY: 'CASE_STUDY',
  TESTIMONIAL: 'TESTIMONIAL',
  LEGAL_INFORMATION: 'LEGAL_INFORMATION',
  PROFILE: 'PROFILE',
  PROGRAMME: 'PROGRAMME',
  TEST_TYPE_FAMILY: 'TEST_TYPE_FAMILY',
  FAQ_CATEGORY: 'FAQ_CATEGORY',
  FAQ_CONTENT: 'FAQ_CONTENT',
  BLOG_PAGE: 'BLOG_PAGE',
  HOMEPAGE: 'HOMEPAGE',
  BASIC_CONTENT: 'BASIC_CONTENT',
  LANDING_PAGE: 'LANDING_PAGE',
  PREPARATION_TOOL_VERSION: 'PREPARATION_TOOL_VERSION',
  CUSTOM_LINK: 'CUSTOM_LINK',
  PRACTICE_TEST: 'PRACTICE_TEST',
  LANDING_PAGE_PARTNER: 'LANDING_PAGE_PARTNER',
  BROKEN_LINK: 'BROKEN_LINK',
};

export const PRISMIC_CUSTOM_TYPE = {
  NEWS: 'NEWS',
  CASE_STUDY: 'CASE_STUDY',
  BASIC_CONTENT: 'BASIC_CONTENT',
  FAQ_CATEGORY: 'FAQ_CATEGORY',
  FAQ_CONTENT: 'FAQ_CONTENT',
  HOMEPAGE: 'HOMEPAGE',
  TESTIMONIAL: 'TESTIMONIAL',
  LEGAL_INFORMATION: 'LEGAL_INFORMATION',
  PROFILE: 'PROFILE',
  TEST_TYPE_FAMILY: 'TEST_TYPE_FAMILY',
  BLOG_PAGE: 'BLOG_PAGE',
  LANDING: 'LANDING',
  PRODUCT_PROGRAMME_FAMILY: 'PRODUCT_PROGRAMME_FAMILY',
};

export const PAGE_TYPE = {
  CONTACT_US_INDIVIDUAL: 'CONTACT_US_INDIVIDUAL',
  CONTACT_US_PROFESSIONAL: 'CONTACT_US_PROFESSIONAL',
  SESSION_SEARCH: 'SESSION_SEARCH',
  SESSION_REGISTER_CANDIDATE: 'SESSION_REGISTER_CANDIDATE',
  PREPARATION_TOOLS: 'PREPARATION_TOOLS',
};

export const FILTER_PREPARATION_TOOL = {
  ALL: 'ALL',
  ONLINE: 'ONLINE',
  BOOK: 'BOOK',
};

export const SOCIAL_NETWORKS = [
  {
    name: 'linkedin',
    href: 'https://www.linkedin.com/company/ets-global/',
  },
  {
    name: 'facebook',
    href: 'https://www.facebook.com/ETSGlobal/',
  },
  {
    name: 'twitter',
    href: 'https://twitter.com/ETSGlobal',
  },
];

export const IDENTITY = {
  INDIVIDUAL: 'individual',
  ORGANISATION: 'organisation',
};

export const PRODUCTS = {
  TOEIC: 'toeic',
  TOEFL: 'toefl',
  TFI: 'tfi',
  ENGLISH: 'english',
  TEACHER: 'teacher',
  OTHER: 'other',
};

export const CONTACT_ROLES = {
  LEGAL_DELEGATED: 'legal_delegated',
  ADMIN: 'admin',
  ACCOUNTANT: 'accountant',
  PURCHASER: 'purchaser',
  TCA: 'tca',
  PROCTOR: 'proctor',
};

export const CONTACT_FORM_TEST_USAGES = {
  RESELLER: 'productReseller',
  ORGANISATION: 'productOrganisation',
};

export const LEAD_FORM_TEST_USAGES = {
  RESELLER: 'reseller',
  ORGANISATION: 'organisation',
};

export const VAT_COMPLIANCE = {
  VAT_NUMBER_OK: 'OK',
  VAT_NUMBER_KO: 'KO',
  VAT_NUMBER_EMPTY: 'Not Checked',
  VAT_NUMBER_INTERNAL_ERROR: 'Not Checked',
  NO_VAT_NUMBER: 'Not Checked',
};

export const SITE_LOCALES = {
  DEBUGGING_TRANSLATION: 'dt',
  ENGLISH: 'en',
  FRENCH: 'fr',
  POLISH: 'pl',
};

export const DEFAULT_COUNTRY = 'fr';

export const COUNTRY = {
  'la-reunion': 're',
  martinique: 'mq',
  guadeloupe: 'gp',
  corse: 'fx',
  guyane: 'gf',
  mayotte: 'yt',
  'saint-barthelemy': 'bl',
  'saint-pierre-et-miquelon': 'pm',
  'iles-du-Vent': 'pf',
};

export const PRACTICE_TEST_CANDIDATE_TYPE = {
  HIGH_SCHOOL_STUDENT: 'HIGH_SCHOOL_STUDENT',
  STUDENT: 'STUDENT',
  WORKER: 'WORKER',
  IS_CLIENT: 'IS_CLIENT',
  IS_NOT_CLIENT: 'IS_NOT_CLIENT',
};

export const ECBlockType = {
  ECRoundImageBlock: 'ECRoundImageBlock',
  ECSquareImageBlock: 'ECSquareImageBlock',
  ECVideoBlock: 'ECVideoBlock',
  ECRichTextEditorBlock: 'ECRichTextEditorBlock',
  ECQuoteBlock: 'ECQuoteBlock',
  ECDiscountBlock: 'ECDiscountBlock',
  ECStepsBlock: 'ECStepsBlock',
  ECIconsBlock: 'ECIconsBlock',
  ECPreparationToolsBlock: 'ECPreparationToolsBlock',
  ECSessionsBlock: 'ECSessionsBlock',
  ECSmallVideoBlock: 'ECSmallVideoBlock',
  ECHorizontalIconContentListBlock: 'ECHorizontalIconContentListBlock',
  ECIconBulletPointListBlock: 'ECIconBulletPointListBlock',
  ECLinksBlock: 'ECLinksBlock',
  ECFaqBlock: 'ECFaqBlock',
  ECTableBlock: 'ECTableBlock',
  ECLegalSentenceForHomeTestBlock: 'ECLegalSentenceForHomeTestBlock',
};

export const CEFRLevel = {
  C1: 'C1',
  B2: 'B2',
  B1: 'B1',
  A2: 'A2',
  A1: 'A1',
};

export const COOKIE_MAX_AGE = 7884000;
export const PAGE_S_MAX_AGE = 60 * 60 * 24;
export const DEFAULT_SESSION_SEARCH_DISTANCE = 1.2;
export const COOKIE_PROMO_CODE_OR_REGISTRATION_ID_MAX_AGE = 86400; // 1 day

export const SCORING_DOCUMENT_TYPE = {
  DIGITAL_SCORE_REPORT: 'digital_score_report',
  PROOF_OF_COMPLETION_SESSION: 'proof_of_completion_session',
};

export const AWEB_URL = env('RAZZLE_AWEB_URL');
export const B2C_WEBSITE_URL = env('RAZZLE_B2C_WEBSITE_URL');

export const AUTH_COOKIE_DOMAIN = env('RAZZLE_AUTH_COOKIE_DOMAIN');
export const AUTH_TOKEN_KEY = 'auth_token';
export const AUTH_TOKEN_KEY_B2C = 'auth_token_b2c';
